// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$meteosol-admin-frontend-primary: mat.define-palette(mat.$indigo-palette);
$meteosol-admin-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$meteosol-admin-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$meteosol-admin-frontend-theme: mat.define-light-theme((
  color: (
    primary: $meteosol-admin-frontend-primary,
    accent: $meteosol-admin-frontend-accent,
    warn: $meteosol-admin-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($meteosol-admin-frontend-theme);

/* You can add global styles to this file, and also import other style files */


html, body {
  height: 100%
}

body {
  margin: 0;
}

* {
  font-family: Muli, europa, sans-serif;
}

:root {
  --primary-color: #666666;
  --secondary-color: #BFBFBF;
  --background-color: #F2F2F2;
  --button-background-color: #BDBDBD;
  --button-text-color: #FFFFFF;
  --meteosol-blue-dark: #0073A8;
  --meteosol-blue-light: #54B9E9;
  --meteosol-yellow: #FFE64F;
  --meteosol-orange: #F59C00;
  --meteosol-red: #D85930;
  --meteosol-green: #5BCA90;
  --table-column-height: 2.5rem;
  --table-connected-columns-height: 4.2rem;
}

h1 {
  font-size: 6.25rem !important;
  font-weight: 700 !important;

}

h2 {
  font-size: 3.125rem !important;
  font-weight: 700 !important;
}

h3 {
  font-size: 1.36rem !important;
  font-weight: 400 !important;
  padding-bottom: 1.2rem !important;
}

h4 {
  font-size: 1.36rem !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

//table style
table {
  width: 100%;
}

th {
  background-color: var(--background-color);
  border-bottom: 0;
  border-bottom-style: none;
}

th.mat-header-cell {
  border-bottom-style: none !important;
  padding: 0.6rem !important;
}

th.mat-header-cell:first-of-type {
  text-align: left;
}

td {
  border-left: 0.3rem solid var(--background-color) !important;
  vertical-align: top;
}

td.mat-cell:first-of-type {
  border-bottom: 0.3rem solid var(--background-color);
  text-align: left;
  align-items: center !important;
  padding: 0 !important;
}

td.mat-cell:last-of-type {
  border-right: 0.3rem solid var(--background-color);
}

td.mat-cell {
  border-bottom: 0.3rem solid var(--background-color) !important;
}

mat-cell {
  border-bottom: 0.3rem solid var(--background-color) !important;
  padding-left: 0.3rem !important;
  height: var(--table-column-height) !important;
}

mat-cell:last-of-type {
  border-bottom: none !important;
}

mat-cell:first-of-type {
  border-bottom: 0.3rem solid var(--background-color) !important;
}


//status style
.status {
  transform: scale(0.7);
  background-color: transparent !important;
}

.OK {
  color: var(--meteosol-green);
  background-color: var(--meteosol-green);
}

.ERROR {
  color: var(--meteosol-orange);
  background-color: var(--meteosol-orange);
}

.OFFLINE {
  color: var(--meteosol-red);
  background-color: var(--meteosol-red);
}

.NEW {
  color: var(--meteosol-blue-light);
  background-color: var(--meteosol-blue-light);
}

.FULL {
  color: var(--primary-color);
  background-color: var(--meteosol-green);
}

.USED {
  color: var(--primary-color);
  background-color: var(--meteosol-orange);
}

.EMPTY {
  color: var(--primary-color);
  background-color: var(--meteosol-red);
}

.UNDEFINED {
  color: var(--meteosol-blue-light);
  background-color: var(--meteosol-blue-light);
}

.mat-tooltip-hide {
  // when hovering over tooltip, it flickers, temporary fix
  //https://github.com/angular/components/issues/24614
  display: none !important;
}
